.image-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the gap between images as needed */
  }
  
  .image-container {
    position: relative;
    width: 23%; /* Adjust the width as needed */
    overflow: hidden;
  }
  
  .image {
    width: 100%;
    height: 200px;
  }
  
  .overlay {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Black background with transparency */
    color: #f1f1f1;
    width: 100%;
    text-align: center;
    padding: 10px 0; /* Adjust padding as needed */
  }
  
  .text {
    font-size: 14px; /* Adjust font size as needed */
  }
  
  @media (max-width: 768px) {
    .image-container {
      width: 48%; /* Adjust the width for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .image-container {
      width: 100%; /* Adjust the width for mobile screens */
    }
  }
  