.card-wrapper{
  display: flex;
}
.modernWay {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.btn-warning{color: white;
background-color: rgb(248 168 2) !important;
}
.bgcard{
  min-height: 500px;
  background-color: #FCF2DA;
}
:focus-visible {
  outline: none!important;
}
.imgcards{
    display: flex;
    background: bisque;
    padding: 10px 25px;
}
.slider {
  width: 100% !important;
  height: 500px;
}
@media (max-width: 767px) {
  .slider {
    height: 150px;
  }
}