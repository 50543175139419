.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: middle;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}
.nav-item{
  font-weight: 700;
  border-right: 0.5px solid rgba(240, 121, 31, 0.76);
}
.dropdown-menu{
      background-color: #fcf2da;
      padding: 4px 2px;
      box-shadow: 0px 1px 13px 0px #f8a8028f;
      border: none;
}
.bg-body-nav {
  --bs-bg-opacity: 1;

  padding: 0;
}
.bgnav2{
  background-color: rgb(248 168 2) !important;
}
.breadcum{
    padding: 43px 3px;
}
.breadcum nav ol li a{
  color: white;
      text-decoration: none;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      line-height: 33.8px;
      text-align: left;
}
.breadcum nav ol li a::after {
  content: '/';
}

.breadcumHome{
  font-size: 26px;
  color: white;
      padding: 0 5px;
}

.textheading2 {
  font-family: Poppins;
  font-weight: 600;
  line-height: 46.8px;
  text-align: left;
  color: #FFFFFF;
}
.Middleitem1{
  text-align: left;
      padding: 0px 12px;
}
.middleheader1{
  background-color: #F8A700A3;
}
.secondHeading{
  font-family: Poppins;
  font-weight: 500;
padding: 9px 0 0 1px;
  color: #623E0D;


}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: 0px;
}

.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-menu>li:hover>.dropdown-submenu {
  display: block;


}
.dropdown-menu>li>.dropdown-submenu {
  background-color: #fcf2da;
  list-style: none;
  text-decoration: none;
}
.submenuarrow{
  float: right;
  padding: 5px 0;
}
.dropdown-item.active,
.dropdown-item:active {
  color: black;
  text-decoration: none;
  background-color:#fcf2da;
}
