.announcment {
  background-color: #FDF7EB;
  padding: 5px 0;
}

.announcment .labelitem {
  background-image: url('../../assets/announc.png');
  background-size: cover;
  background-repeat: round;

}

.blink {
  color: red;
  font-family: sans-serif;
  font-size: 21px;
}