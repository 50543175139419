.gallery{
  width: 100%;
  min-height: 100vh;
     background-image: url('../../../public/Gallery/galleryBG.jpg');
     background-repeat: no-repeat;
     background-size: cover;
}
.imgsize {
  width: 100%;
 height: 100%;
  object-fit: cover;
}

.glycard {
  height: 100%;
}
.imgsizebox {
      width: 100%;
      height: -webkit-fill-available;
}
.cradlink{
  text-decoration: none;
  color: brown;
}
.cardArrow{
  vertical-align: middle;
}
.textsize{
      margin-top: 0;
      margin-bottom: 0rem;
}



