@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/* Middle Section Line */
.rowafter{
      width: 30%;
      background: #F0791F;
      text-align: center;
      height: 3px;
    border-radius: 50%;
}

/* Leader Team */
.Leaders {
  min-height: 600px;
  background-color: #FAC455;
 background-image: url('../../assets/wave.png');
 background-size: cover;
 background-repeat: no-repeat;
}
.textsmall{
  font-size: 18px;
  color: #B01432;
}
.textheading{
  font-size: 36px;
  color: #653C03;
  font-weight: 800;
    font-family: "Poppins",
    sans-serif;
    line-height: 1.1;
    margin: auto;
}


.socialicon{
  font-size: 22px;
  padding: 0 10px;
}
.fa-facebook{
  color: #4275C8;
}
.fa-twitter{
  color: #00C2FF;
}
.fa-instagram{
  color:#FB003C
}
.teamName{
  color: #623E0D;
}
.teamdesig{
   color: #B01432;
}
.Leaderimgbg{
  background-color: #F8A802;
  height: 320px;
}
@media (max-width: 767px) {
  .leadersec {
    display: none;
  }
}
.Leaderbgcolor{
background-color: #9773FF;
color: white;
}
.Leaderbgcolor1{
background-color: #E88060;
color: white;
}
.fa-chevron-right{
  float: inline-end;
}
ul li a {
  text-decoration: none;
  color: #2f1d03;
}

/* Services */
.service img{
    max-height: 250px;
    object-fit: cover;
}
.service{
 /* Rectangle 1338 */
 width: 298px;
 height: 327px;
 left: 0px;
 background: url('../../../public/Services/services-1.jpg');
     background-size: cover;
     background-repeat: no-repeat;
 border-radius: 10px;

}
.service1 {
  /* Rectangle 1338 */
  width: 298px;
  height: 327px;
  left: 0px;
  background: url('../../../public/Services/services-2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;

}
.service2 {
  /* Rectangle 1338 */
  width: 298px;
  height: 327px;
  left: 0px;
  background: url('../../../public/Services/services-3.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;

}
.service3 {
  /* Rectangle 1338 */
  width: 298px;
  height: 327px;
  left: 0px;
  background: url('../../../public/Services/services-5.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;

}
.card-img-overlay{
  /* Group 11672 */
 height: fit-content;
  top: 186px;
  justify-self: center;
  background-color: #ffffff;
  width: 80%;
  color: #B01432;
  border-radius: 5px;
}
.card-img-overlay .card-title {
font-size: 22px;
font-weight: 600;
line-height: 23.32px;
}
.bookicon{
 top: 162px;
 position: absolute;
 z-index: 1;
 background: rgb(255, 255, 255);
 border-radius: 50%;
 height: 12%;
 width: 12%;
 place-content: center;
 align-self: center;
 color: #B01432;
 font-size: 20px;
}

/* ========== Card */
.cardrow{
  text-align: -webkit-center;
}

/* ========== Map */
.teststyle{
  width:1px;
  word-wrap: break-word;
  white-space:pre-wrap;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  font-size: 35px;
  font-weight: 500;
  color: #623E0D;

}
.bgcolor{
     background-color: #F8A802;
     height: fit-content;
     display: flex;
     align-self: center;
     text-align: center;
        padding: 13px 7px;
        width: 5%;
border: 1px solid #DF5532;
}

@media (max-width: 768px) {
  .teststyle {
    display: none;
  }
   .bgcolor {
     display: none;
   }
}

.responsive-map-container {
  position: relative;
  padding-top: 30px;
  height: 300px;
  overflow: hidden;
}

.responsive-map-container iframe,
.responsive-map-container object,
.responsive-map-container embed {
  position: absolute;
  top: 11px;
  left: 0;
  width: 100%;
}
/* newsletter */
.newsletter{
  background-color: #fbe4b5;
    padding: 11px 6px 1px 6px;
}
.newsimg{
  height: 175px;
  width: 54%;
}
.newsheading{
  font-size: 35px;
  color: #B01432;
  font-weight: 700;
      line-height: 1.0;
}
.newsdic{
   font-size: 20px;
   color: #B01432;
   font-weight: 700;
}
.newsbutton{
  background-color: #B01432;
  font-weight: 600;
  color: white;
}
.textbutton{
      border: 1px solid #B01432;
}
::placeholder {
  font-weight: 700;
  text-align: center;
  color: #a3a6ab;
}
::-webkit-input-placeholder {
   color: #a3a6ab;
}

/*============= Gallry Card Slider ============= */
.carousel-dark .carousel-caption {
  color: #fff;
  background: #000000a3;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 40px;
}
.galleryDate {
  position: absolute;
  top: 0px;
  padding: 8px 10px;
}

.Gnewsstyle{
  font-size: 11px;
}

/* Social Media */

.Socialcontainer{
  min-height: 96vh;
  background-color: #fdf7eb;
}
/*========== Brand Slider========== */

.bgcard1 {
  min-height: 465px;
  background-color: #fdf7eb;
}
.bgcard2 {
  min-height: 300px;
  background-color: #ffda82;
}
.BrandImg{
  border-radius: 23px;
  min-height: 150px;
  width: 100%;
  box-shadow: 0px 0px 15px 0px #F0791F inset;
  border: 2.96px solid #F0791F

}
/* Spotlight */
.spotlightlink{
  text-decoration: none;
  color: #B01432;
font-family: Poppins;
font-size: 13.32px;
font-weight: 500;
line-height: 21.98px;
text-align: left;
}

/* ================ Account Department ============== */

.accountBGImg{
  min-height: 242px;
  background-image: linear-gradient(rgba(60, 60, 60, 0.45),
    rgba(60, 60, 60, 0.45)),
  url('../../../public/BgImg/account.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.accounttext{
  font-family: Poppins;
  font-weight: 400;
  line-height: 26px;
text-align: left;

}
.accountImg{
  border-radius: 5px;
  width: 100%;
}
.Accbgcolor{
     background-color: #FCF2DA;
     min-height: 96vh;
}
.bgsize{
  min-height: 50vh;
}

/* ================= NewsLetter ============ */
.theadebg thead tr th{
  background: #F8A700;
}


/* =========== About Us =========== */
.aboutus ul li {
  text-decoration: none;
  list-style: none;
}
.aboutus ul li i {
color: #ff8900;
}
.aboutus .card-title {
background: #ff8900;
padding: 5px 5px; 
}

/* ====Importlinks====== */
.list-group-item a{
    text-decoration: none;
    list-style: none;
    color: black;

}

/* ============== Contact Us ============ */
.contectuslink a{
  text-decoration: none;
  color: #b64827;

}