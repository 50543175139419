/* ================ Footer =============== */
.Footerbg {
  min-height: 55vh;
  background: linear-gradient(180deg, #F8A802 0%, #FAC455 100%);
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.Footerbg .img {
  width: 100%;
  height: auto;
}

.footerqr {
  background-color: white;
  padding: 5px;
}

.FHeading {
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 36.4px;
  text-align: left;

}
.group1{
      text-align: left;
}
.group ul li{
  font-family: Poppins;
  font-size: 18.74px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;

}
.group1 ul li {
  text-align: left;
  text-decoration: none;
  list-style: none;
  display: flex;
  padding: 0;

}
.group1 ul {
  padding: 2px;

}
.group1 ul li i {
      padding: 4px 6px;
          font-size: larger;
}
.FooterForm{
  text-align: left;
      padding: 0px 14px;
}
.formLableName {
  margin-bottom: 0.1rem;
  font-weight: 600;
}
.prefooter{
  min-height: 76px;
background: #F1AA16;

}
.paragraph{
  font-family: Poppins;
  font-size: 12.46px;
  font-weight: 500;
  line-height: 18.68px;
  text-align: left;

}
.lefttext {
  text-align: right !important;
  line-height: 1.3;
}
.linkcolor{
 list-style: none;
 text-decoration: none;
 color: black;
 cursor: pointer;
}