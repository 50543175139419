/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.Headersearch{
  /* Rectangle 1354 */

  max-width: 145px;
  max-height: 24px;
  top: 6px;
  background: #FFFFFF;
  border-radius: 10px;

}
.topheader{
      background-color: #FAD68D;
      box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
}
.topicon a{
  color: rgb(8, 12, 72);
  
}
.timetext{
  font-family: "Poppins",
  sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

/* Middle Header */
.middleheader{
  background-color: #FAC455;
  min-height: 88px;
 
}
.imagestylediv{
 border-right: 0.5px solid rgba(240, 121, 31, 0.76);
 padding: 0 17px 0 0;
 align-content: center;
 height: inherit;
  text-align: -webkit-center;
  line-height: 0.1;
}
.imagestyledivqr{
   align-content: center;
   height: inherit;
   text-align: -webkit-center;
  align-content: center;
}
.img-responsive{
  /* image 3 */
  max-width: 63px;
  max-height: 63px;
  /* Line 11 */
}
.hdngmiddle{
  font-size: 20px;
  font-weight: 700;
  color: #623E0D;
      line-height: 1.2;
 
}
.Middleitem{
  padding: 20px 0;
      align-content: center;
}
.Middleitem1 {
  text-align: left;
  padding: 0px 12px;
}
.whattext{
  line-height: normal;
}
.compqr{
  float: inline-start;
  line-height: normal;
}
.imagestylediv span {
  line-height: normal;
}
.dwnbtn{
  width: max-content;
}
.qrscanner{
  font: 12px;
}
.btncolor{
  background-color: #F8A802;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
      box-shadow: 0 0 0 0rem;
}
@media (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }
}