.alertnews ul li{
  font-size:14px;
  font-weight: 400;
      padding: 4px 0;
}
.annusment{
  text-decoration: none;
      color: #6c3501;
}
.alertsecont{
  font-size: 13px;
}
.alertsecont ul table tr td a {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.AlertSecontHeader{
  background-color: #008BF9;
}





